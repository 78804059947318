// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-section-template-js": () => import("/zeit/d88e5d0/src/templates/section-template.js" /* webpackChunkName: "component---src-templates-section-template-js" */),
  "component---src-templates-page-template-js": () => import("/zeit/d88e5d0/src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("/zeit/d88e5d0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-i-18-n-redirect-js": () => import("/zeit/d88e5d0/src/i18n/Redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-about-js": () => import("/zeit/d88e5d0/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-features-js": () => import("/zeit/d88e5d0/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-guide-js": () => import("/zeit/d88e5d0/src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("/zeit/d88e5d0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/zeit/d88e5d0/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/zeit/d88e5d0/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("/zeit/d88e5d0/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

