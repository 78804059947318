import React from "react";
import { _locales } from "./locales";
import { navigate } from "gatsby";

// when you need to add a new translation, add it to ./locales.js
const defaultContext = { locale: "en" };
export const I18n = React.createContext(defaultContext);

class I18nContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locales: _locales,
      locale: this.chooseAndSaveLocale(),
      updateLocale: this.updateLocale
    };
  }

  saveCookie = locale => {
    return window.localStorage.setItem("locale", locale);
  };

  getCookie = () => {
    const locale = window.localStorage.getItem("locale");
    return locale;
  };

  // grab from browser. slice if needed. check if in array. return val.
  detectLocale = () => {
    const fallback = "en";
    const detected =
      window.navigator.userLanguage || window.navigator.language || fallback;
    let locale = "";
    if (detected.length > 2) {
      locale = detected.slice(0, 2);
    } else if (locale.length < 2) {
      locale = fallback;
    }
    return _locales.includes(locale) ? locale : fallback;
  };

  chooseAndSaveLocale = () => {
    const cookie = this.getCookie();
    if (cookie) {
      return cookie;
    } else {
      const locale = this.detectLocale();
      this.saveCookie(locale);
      return locale;
    }
  };

  // change the context and update the browser path
  updateLocale = locale => {
    if (locale !== this.state.locale) {
      this.setState({ locale });
      const arr = window.location.pathname.split("/");
      const currentPath = arr.slice(2).join("/"); // "guide/case-studies/honduras-map"
      navigate(`/${locale}/${currentPath}`);
    }
    this.saveCookie(locale);
  };

  render() {
    return (
      <I18n.Provider value={this.state}>{this.props.children}</I18n.Provider>
    );
  }
}

export default I18nContext;
