module.exports = [{
      plugin: require('/zeit/d88e5d0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"about.canvis.app","short_name":"about.canvis.app","start_url":"/","background_color":"#26A69A","theme_color":"#26A69A","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('/zeit/d88e5d0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-117992046-3","head":false,"anonymize":true,"respectDNT":true,"sampleRate":100,"siteSpeedSampleRate":1,"cookieDomain":"https://about.canvis.app"},
    },{
      plugin: require('/zeit/d88e5d0/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('/zeit/d88e5d0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
